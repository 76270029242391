import React from 'react'
import "../App.css";

export default function Footer() {

  return <>
    <div className="footer-container navbar fixed-bottom">
      <div className="footer-bar">
        <p></p>
      </div>
    </div>
  </>
}